import { IconButton } from '@patrianna/core-components'
import cx from 'classnames'

import classes from './styles.module.scss'

export type BackButtonType = {
  handler?: Function
  className?: string
  color?: 'white' | 'black'
  children?: any
}

export default function BackButton(props: BackButtonType) {
  return (
    <IconButton
      className={cx(props.className, classes.root, classes[props?.color || 'black'])}
      onClick={() => {
        props.handler?.()
      }}
      data-test='modal-back-button'
      data-testid='modal-back-button'
    >
      <svg
        width='20'
        height='14'
        viewBox='0 0 20 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.icon}
      >
        <path
          d='M7.93169 12.875C8.25515 12.5418 8.25515 12.0034 7.93169 11.6701L4.71362 8.35454L17.2956 8.35454C17.7518 8.35454 18.125 7.97 18.125 7.5C18.125 7.03 17.7518 6.64546 17.2956 6.64546L4.70532 6.64546L7.92339 3.32985C8.24686 2.99658 8.24686 2.45822 7.92339 2.12495C7.59993 1.79168 7.0774 1.79168 6.75394 2.12495L2.1176 6.90182C1.79413 7.2351 1.79413 7.77345 2.1176 8.10672L6.76223 12.8751C7.07741 13.2083 7.60822 13.2083 7.93169 12.875Z'
          fill='white'
        />
      </svg>

      {props.children}
    </IconButton>
  )
}
