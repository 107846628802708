import { IconButton } from '@patrianna/core-components'
import type { GameProduct, GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import { useCallback } from 'react'

import { useAuth } from 'app/context/auth'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { addFavoriteGame, isFavoriteGameSelector, removeFavoriteGame } from 'store/modules/categoryGames'
import { openSnackbar } from 'store/modules/snackbar/actions'

import ConfirmationMessageSnackbar from '../ConfirmationMessageSnackbar'

import classes from './styles.module.scss'

export type FavouriteButtonProps = {
  gameProduct: GameProduct | GameProductShort
  className?: string
}

export default function FavoriteButton({ gameProduct, className }: FavouriteButtonProps) {
  const { isLoggedIn } = useAuth()
  const isFavorite = useAppSelector((state) => isFavoriteGameSelector(state, gameProduct?.code))
  const dispatch = useAppDispatch()

  const handleSnackbar = (message: string) => {
    dispatch(
      openSnackbar({
        message: () => <ConfirmationMessageSnackbar message={message} />,
        positionVertical: 'bottom',
        positionHorizontal: 'center',
        autoHide: 5000,
      })
    )
  }

  const onFavoriteClick = useCallback(() => {
    if (isFavorite) {
      dispatch(removeFavoriteGame(gameProduct))
      handleSnackbar('common.game_was_removed')
    } else {
      dispatch(addFavoriteGame(gameProduct))
      handleSnackbar('common.game_was_added')
    }
  }, [isFavorite, gameProduct])

  if (!gameProduct || !isLoggedIn) {
    return null
  }

  return (
    <IconButton
      className={cx(classes.favoriteButton, { [classes.isFavorite]: isFavorite }, className)}
      onClick={onFavoriteClick}
      data-test='favourite-button'
    >
      {isFavorite && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='54'
          height='54'
          viewBox='0 0 54 54'
          fill='none'
          className={classes.shadow}
        >
          <g filter='url(#filter0_f_3494_119461)'>
            <path
              d='M19.5 15.0237C22.7415 14.729 25.6393 15.8635 27.4736 16.9478C28.38 17.4836 29.5806 17.4317 30.4896 16.9002C32.6291 15.6492 35.6273 15.0237 38 15.0237C41 15.0237 44 19.0237 44 23.0237C44 26.2237 40.6667 30.357 39 32.0237C36.5 34.357 31 39.0237 29 39.0237C26.5 39.0237 26.5 37.5237 20.5 33.5237C15.7 30.3237 14.1667 25.1903 14 23.0237C14 20.5237 15.1 15.4237 19.5 15.0237Z'
              fill='#FF8D95'
            />
          </g>
          <defs>
            <filter
              id='filter0_f_3494_119461'
              x='0'
              y='0.976318'
              width='58'
              height='52.0474'
              filterUnits='userSpaceOnUse'
              color-interpolation-filters='sRGB'
            >
              <feFlood flood-opacity='0' result='BackgroundImageFix' />
              <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
              <feGaussianBlur stdDeviation='7' result='effect1_foregroundBlur_3494_119461' />
            </filter>
          </defs>
        </svg>
      )}
      <img src={`${process.env.IMG_ORIGIN}/common/favorite.png?v=2`} width={25} height={20} alt='favorites icon' />
    </IconButton>
  )
}
