import { SnackbarContent } from '@patrianna/core-components'
import cx from 'classnames'

import AnimatedOverlay from 'dialogs/components/AnimatedOverlay'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import { useAppDispatch } from 'store/hooks'
import { closeSnackbar } from 'store/modules/snackbar/actions'

import ConfirmationMessageContent from './ConfirmationMessageContent'
import classes from './ConfirmationMessageSnackbar.module.scss'

type ConfirmationMessageSnackbarProps = {
  className?: string
  message?: string
}

export default function ConfirmationMessageSnackbar({ message, className }: ConfirmationMessageSnackbarProps) {
  const dispatch = useAppDispatch()
  const isBottomTabsBar = useIsBottomTabsBar()

  const handleOverlayClick = () => {
    dispatch(closeSnackbar())
  }

  return (
    <AnimatedOverlay
      id='confirmation-message-snackbar'
      mobileDirection='center'
      desktopDirection='center'
      overlayHandler={handleOverlayClick}
    >
      <SnackbarContent
        className={cx(className, classes.snackbarContent, { [classes.additionalMargin]: isBottomTabsBar })}
        message={<ConfirmationMessageContent message={message} />}
      />
    </AnimatedOverlay>
  )
}
